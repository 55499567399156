import {
  booleanAttribute,
  Component,
  EventEmitter,
  forwardRef,
  input,
  Input,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { ROAM_VALIDATORS } from "@app/core/const/validator-error-message.const";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { InputDirectiveModule } from "@app/shared/directives/input-directive/input-directive.module";
import { currencyFormatter } from "@app/core/helper/currency.helper";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    InputDirectiveModule,
  ],
  selector: "app-roam-input",
  templateUrl: "./roam-input.component.html",
  styleUrls: ["./roam-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoamInputComponent),
      multi: true,
    },
  ],
})
export class RoamInputComponent implements ControlValueAccessor {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() name: string = "";
  @Input() className: string = "";
  @Input() readOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeholder: string = "";
  @Input() floatingLabel: boolean = true;
  @Input() public ctrName: string = "";
  @Input() public ctr: AbstractControl | FormControl | null = null;
  @Input() isRef!: boolean;

  readonly required = input(false, { transform: booleanAttribute });

  @Input()
  public numberOnly: boolean = false;

  @Input()
  public currency: boolean = false;

  public validators: any = ROAM_VALIDATORS;

  currentValue: string = "";
  onChange: any = () => {};
  onTouch: any = () => {};

  set value(val: string) {
    if (this.currency) {
      this.currentValue = currencyFormatter(val)!;
    } else {
      this.currentValue = val;
    }

    this.onChange(val);
    this.onTouch(val);
    this.valueChange.emit(this.currentValue);
  }

  constructor() {}

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouch = onTouched;
  }

  onValueChange() {
    this.value = this.currentValue;
    this.valueChange.emit(this.currentValue);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
